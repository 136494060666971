import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SidenavService, Submenu } from 'src/app/sidenav.service';

@Component({
    selector: 'app-calendrier',
    templateUrl: './calendrier.component.html',
    styleUrls: ['./calendrier.component.scss'],
    standalone: false
})
export class CalendrierComponent implements OnInit {
  constructor(private titleService: Title, public sidenavService: SidenavService) {
    this.titleService.setTitle('Calendrier');
  }

  ngOnInit(): void {
    this.sidenavService.setActiveSubmenu(Submenu.MEMBRES);
  }
}
