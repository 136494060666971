import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SidenavService, Submenu } from 'src/app/sidenav.service';

@Component({
    selector: 'app-telechargements',
    templateUrl: './telechargements.component.html',
    styleUrls: ['./telechargements.component.scss'],
    standalone: false
})
export class TelechargementsComponent implements OnInit {
  constructor(private titleService: Title, public sidenavService: SidenavService) {
    this.titleService.setTitle('Téléchargements');
  }

  ngOnInit(): void {
    this.sidenavService.setActiveSubmenu(Submenu.MEMBRES);
  }
}
